import { useState, useEffect } from "react";
import { isPlatform } from "@ionic/react";

import { Camera, CameraResultType, CameraSource, Photo } from "@capacitor/camera";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Storage } from "@capacitor/storage";
import { Capacitor  } from "@capacitor/core";
import { resolve } from "dns";
import { rejects } from "assert";

const PHOTO_STORAGE = 'photos';
export function usePhotoGallery() {
    const [photos, setPhotos] = useState<UserPhoto[]>([]);
    const [fileSize, setFileSize] = useState(0);

    useEffect(() => {
        const updateFileSize =async () => {
            let storage = await Storage.get({key: PHOTO_STORAGE});
            let spaceUsed = storage.value?.length;
            let percentageUsed = 0;
            if(spaceUsed) {
                percentageUsed = spaceUsed/5200000;
            }
            // console.log(storage.value);
            console.log(storage.value?.length);
            console.log(percentageUsed);
            setFileSize(percentageUsed);
            console.log(fileSize);
        }
        updateFileSize();
    }, [photos]);

    const savePicture = async (photo: Photo, fileName: string): Promise<UserPhoto> => {
        let base64Data: string;
        // "hybrid" will detect Cordova or Capacitor;
        if (isPlatform('hybrid')) {
          const file = await Filesystem.readFile({
            path: photo.path!,
          });
          base64Data = file.data;
        } else {
          base64Data = await base64FromPath(photo.webPath!);
        }
        const savedFile = await Filesystem.writeFile({
          path: fileName,
          data: base64Data,
          directory: Directory.Data,
        });
      
        if (isPlatform('hybrid')) {
          // Display the new image by rewriting the 'file://' path to HTTP
          // Details: https://ionicframework.com/docs/building/webview#file-protocol
          return {
            filepath: savedFile.uri,
            webviewPath: Capacitor.convertFileSrc(savedFile.uri),
          };
        } else {
          // Use webPath to display the new image instead of base64 since it's
          // already loaded into memory
          return {
            filepath: fileName,
            webviewPath: photo.webPath,
          };
        }
      };
    
    useEffect(() => {
        const loadSaved = async () => {
            const { value } = await Storage.get({ key: PHOTO_STORAGE });
          
            const photosInStorage = (value ? JSON.parse(value) : []) as UserPhoto[];
            // If running on the web...
            if (!isPlatform('hybrid')) {
              for (let photo of photosInStorage) {
                const file = await Filesystem.readFile({
                  path: photo.filepath,
                  directory: Directory.Data,
                });
                // Web platform only: Load the photo as base64 data
                photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
              }
            }
            setPhotos(photosInStorage);
        };
        loadSaved();
    }, []);

    const takePhoto = async () => {
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100,
        }).catch(error => {
            
        }) as Photo;
        
        if(photo) {
            const fileName = new Date().getTime() + '.jpeg';
            const savedFileImage = await savePicture(photo, fileName);
            const newPhotos = [savedFileImage, ...photos];
            setPhotos(newPhotos);
            await Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) });
        }
    };

    const removePhoto = async (filename: string) => {
        let newPhotos:UserPhoto[] = [];

        if(filename == "latest") {
            if(photos.length > 0) {
                newPhotos = photos.filter(photo => photo.filepath != photos[0].filepath);
            }
        } else {
            newPhotos = photos.filter(photo => photo.filepath != filename);
        }
        setPhotos(newPhotos);
        await Storage.set({key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) });
    }

    const clearPhotos = async () => {
        setPhotos([]);
        await Storage.remove({ key: PHOTO_STORAGE });
    }

    return {
        photos,
        takePhoto,
        removePhoto,
        clearPhotos,
        fileSize,
    };
}

export async function base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            if(typeof reader.result === "string") {
                // console.log("!! result !!", reader.result);
                resolve(reader.result);
            } else {
                reject("Method did not return a string");
            }
        };
        reader.readAsDataURL(blob);
    });
}

export interface UserPhoto {
    filepath: string;
    webviewPath?: string; 
}